import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import image1 from '../img/etude/Students-1.jpg';
import image2 from '../img/etude/Students-2.jpg';
import banneryellow from '../img/shape/BG-1.png'

class Service extends Component {

    render() {

        return (
        <section className="about__area pt-90 pb-150">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6">
                     <div className="about__thumb-wrapper">
                        <div className="about__thumb ml-100">
                           <img src={image1} alt="img not found" />
                        </div>
                        <div className="about__banner mt--75">
                           <img src={image2} alt="img not found" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6">
                     <div className="about__content pl-70 pr-60 pt-25">
                        <div className="section__title-wrapper mb-25">
                           <h2 className="section__title">
                              <span className="yellow-bg-big">
                              Organisation  <img src={banneryellow} alt="img not found" />
                           </span> des études
                              </h2>
                           <p>
                           Ce programme combine l'organisation traditionnelle d'une école 
                           d'ingénieur (cycle préparatoire intégré de deux ans + un cycle d'ingénieur de trois ans) 
                           avec le modèle universitaire américain
                           </p>
                        </div>
                        <div className="about__list mb-35">
                           <ul>
                              <li className="d-flex align-items-center"><i><FontAwesomeIcon icon={['fas', 'check']} /></i> 
                              Un cycle préparatoire intégré de deux ans permettant à l'étudiant d'acquérir les connaissances fondamentales requises pour les sciences de l'ingénieur (Mathématiques, Physique, chimie, Dessin industriel....)
                              </li>
                              <li className="d-flex align-items-center"><i><FontAwesomeIcon icon={['fas', 'check']} /></i> 
                             Un cycle d'ingénieur de 3 ans aboutissant à l'obtention d'un diplôme d'ingénieur. Les étudiants effectuant la mobilité chez l'un des partenaires et préparant le Master, obtiendront également le Master of Science du partenaire GIT ou MSU.

                              </li>
                              
                           </ul>
                        </div>
                        <a className="e-btn e-btn-border">Voir Plus</a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Service;