import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/thumbs";  
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import background from '../img/slider/slider-2.jpg'
import banner from '../img/shape/BG-1.png'
// import required modules
import { FreeMode, Navigation, Thumbs, EffectFade } from "swiper";

export default function App() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const top100Films=[];

  return (
    <>
            <div className="single-slider swiper-slide slider__height d-flex align-items-center slider__overlay" style={{ backgroundImage:"url(" + { background } + ")"}}>
                <div className="container pb-10">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6  col-md-6  col-sm-12">
                            <div className="slider__content pb-80 content">
                                <h3 className="slider__title">#BGHIT <span className="yellow-bg">NKOUN <img src={banner} alt="img not found"/> </span>INGENIEUR</h3>
                                <span>Rejoignez l'École d'ingénierie de l'Université Internationale de Rabat</span>
                                <span className="text-white">Diplôme Reconnu par l'État</span>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                        <form action="#" className='formHeader'>
                                 <h2 className='text-center titleform'>Candidats Bacheliers </h2>
                                       <div className='row'>
                                         <div className='col-md-6 mt-2'>
                                         <TextField id="outlined-basic" label="Nom" variant="outlined" fullWidth  className='bg-input'/>
                                          </div> 
                                          <div className='col-md-6 mt-2'>
                                         <TextField id="outlined-basic" label="Prénom" variant="outlined" fullWidth  className='bg-input'/>
                                          </div> 
                                          <div className='col-md-12 mt-2'>
                                         <TextField id="outlined-basic" label="Télephone" variant="outlined" fullWidth  className='bg-input'/>
                                          </div> 
                                          <div className='col-md-12 mt-2'>
                                         <TextField id="outlined-basic" label="Email" variant="outlined" fullWidth  className='bg-input'/>
                                          </div> 
                                          <div className='col-md-6 mt-2'>
                                          <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            className='bg-input'
                                            options={top100Films}
                                            renderInput={(params) => <TextField {...params} label="Année d'obtention BAC" />}
                                          />
                                          </div>
                                          <div className='col-md-6 mt-2'>
                                          <Autocomplete
                                            disablePortal
                                            className='bg-input'
                                            id="combo-box-demo"
                                            options={top100Films}
                                            renderInput={(params) => <TextField {...params} label="Ville" />}
                                          />
                                          </div>
                                       <div className='col-md-12 text-center mt-2'>
                                          <button className="g-btn px-5 "> <span></span> Je m’inscris</button>
                                       </div> 
                                       </div>
                              </form>
                        </div>
                    </div>
                </div>
            </div>
            <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        <SwiperSlide>
            <div className="slider__nav-item swiper-slide item1bg item-1">
                <div className="slider__nav-content">
                    <h4>Acquérir des compétences <br />scientifiques, techniques<br /> et professionnelles</h4>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slider__nav-item swiper-slide blue-bg item-2" >
                <div className="slider__nav-content">
                    <h4>S’adapter au milieu <br />industriel dans<br /> le domaine de l’automobile</h4>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slider__nav-item swiper-slide pink-bg item-3" >
                <div className="slider__nav-content">
                    <h4>Etre apte au travail<br /> en groupe et<br /> à la communication</h4>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slider__nav-item swiper-slide green-bg item-4">
                <div className="slider__nav-content">
                    <h4>Etre capable <br /> de résoudre des <br />  problèmes industriels</h4>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
