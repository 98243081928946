import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import banner1 from '../img/contact/mainContact.jpg'
import banner2 from '../img/contact/second.jpg'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import banneryellow from '../img/shape/BG-1.png'

class Contact extends Component {

    render() {

        return (
            <section className="contact__area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-7 col-xl-7 col-lg-6">
                     <div className="contact__wrapper">
                        <div className="section__title-wrapper mb-40">
                           <h2 className="section__title">Candidats <span className="yellow-bg yellow-bg-big">Bacheliers<img src={banneryellow} alt="img not found"/></span></h2>
                           <p>Découvrez le programme en plus de détail</p>
                        </div>
                        <div className="contact__form">
                           <form action="assets/mail.php">
                              <div className="row">
                                 <div className="col-xxl-6 col-xl-6 col-md-6 mt-2">
                                    <TextField id="outlined-basic" label="Nom" variant="outlined" fullWidth />
                                 </div>
                                 <div className="col-xxl-6 col-xl-6 col-md-6 mt-2" >
                                 <TextField id="outlined-basic" label="Prénom" variant="outlined" fullWidth />

                                 </div>
                                 <div className="col-xxl-12 mt-2">
                                 <TextField id="outlined-basic" label="Télephone" variant="outlined" fullWidth />

                                 </div>
                                 <div className="col-xxl-12 mt-2">
                                 <TextField id="outlined-basic" label="Email" variant="outlined" fullWidth />
                                 </div>
                                 <div className='col-md-6 mt-2'>
                                          <Autocomplete
                                            disablePortal
                                            className='selectDate'
                                            id="combo-box-demo"
                                            options={top100Films}
                                            renderInput={(params) => <TextField {...params} label="Année d'obtention BAC" />}
                                          />
                                          </div>
                                          <div className='col-md-6 mt-2'>
                                          <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            className='selectVille'
                                            options={top100Films}
                                            renderInput={(params) => <TextField {...params} label="Ville" />}
                                          />
                                 </div>
                                 <div className="col-xxl-12 mt-3">
                                    <div className="contact__btn">
                                       <button type="submit" className="e-btn">Je m’inscris</button>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1">
                  <div className="about__thumb-wrapper">
                        <div className="about__thumb mr-100 mt-30">
                           <img src={banner1} alt="img not found" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section> 
        );
    }
}
const top100Films = [];

export default Contact;